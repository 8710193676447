/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from 'react'
import PropTypes from 'prop-types'
import { Text, jsx, Box, useThemeUI } from 'theme-ui'
import { alpha, lighten } from '@theme-ui/color'

const Tag = ({ tag }) => {
  const { colorMode } = useThemeUI()
  const bgColor =
    colorMode === 'light'
      ? alpha(tag.color, 0.1).call()
      : alpha(lighten(tag.color, 0.3).call(), 0.1).call()

  return (
    <Box
      sx={{
        textAlign: 'center',
        py: 1,
      }}
    >
      <Text
        variant="tag"
        sx={{
          color: tag.color,
          backgroundColor: bgColor,
          paddingLeft: 2,
          paddingRight: 2,
          marginRight: 1,
          borderRadius: 4,
        }}
      >
        {tag.name}
      </Text>
    </Box>
  )
}

Tag.propTypes = {
  tag: PropTypes.shape({
    name: PropTypes.string,
    color: PropTypes.string,
  }),
}

export default Tag
