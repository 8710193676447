/** @jsx jsx */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { jsx, Grid, Box, Flex, Select, Label, Text } from 'theme-ui'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ArticleCard from '../components/articleCard'

const Category = props => {
  const [sort, setSort] = useState('price-asc')

  const {
    cms: { category },
  } = props.data

  const occurrences = {
    m: 12,
    y: 1,
  }

  const sortArticles = event => {
    event.persist()
    setSort(_ => {
      category.articles.sort((a, b) => {
        const value_a = a.price.value * occurrences[a.price.occurrence]
        const value_b = b.price.value * occurrences[b.price.occurrence]
        return event.target.value.split('-')[1] === 'asc'
          ? value_a - value_b
          : value_b - value_a
      })

      return event.target.value
    })
  }

  return (
    <Layout {...props}>
      <SEO title={category.name} />
      <Flex
        sx={{
          flexDirection: ['column', 'column', 'row', 'row'],
          justifyContent: 'flex-start',
          py: 3,
          pr: 1,
        }}
      >
        <Text variant="heading" sx={{ fontSize: 7 }}>
          {category.name}
        </Text>
        <div
          sx={{ mx: 'auto', display: ['none', 'none', 'inherit', 'inherit'] }}
        />
        <Flex
          gap={2}
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            flexGrow: [1, 1, 0, 0],
            justifyContent: [
              'flex-start',
              'flex-start',
              'flex-end',
              'flex-end',
            ],
            py: [0, 3, 3],
          }}
        >
          <Box sx={{ pr: 2 }}>
            <Label htmlFor="sound" sx={{ fontSize: 1, fontWeight: 'regular' }}>
              Sort by
            </Label>
          </Box>
          <Flex as="form" onSubmit={e => e.preventDefault()}>
            <Select
              id="sort"
              name="sort"
              variant="sortSelect"
              value={sort}
              aria-label="Sort"
              onChange={sortArticles}
            >
              <option value="price-asc" aria-label="Price (lowest to highest)">
                Price (lowest to highest)
              </option>
              <option value="price-desc" aria-label="Price (highest to lowest)">
                Price (highest to lowest)
              </option>
            </Select>
          </Flex>
        </Flex>
      </Flex>
      <Grid
        gap={3}
        columns={[1, 2, 2, 3]}
        sx={{
          flexWrap: 'wrap',
        }}
      >
        {category.articles &&
          category.articles.map(article => (
            <Box key={article.name}>
              <ArticleCard article={article} />
            </Box>
          ))}
      </Grid>
    </Layout>
  )
}

Category.propTypes = {
  data: PropTypes.shape({
    cms: PropTypes.shape({
      category: PropTypes.shape({
        articles: PropTypes.arrayOf(PropTypes.object),
        name: PropTypes.string,
      }),
    }),
  }),
}

export default Category

export const query = graphql`
  query($categoryId: String!) {
    cms {
      category(id: $categoryId) {
        name
        color
        articles {
          id
          name
          hearts
          description
          brand_color
          link
          available_free
          logo {
            src
            title
          }
          categories {
            name
            color
          }
          price {
            value
            occurrence
          }
        }
      }
    }
  }
`
