/** @jsx jsx */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  jsx,
  Card,
  Grid,
  Box,
  Flex,
  Image,
  Text,
  IconButton,
  useThemeUI,
} from 'theme-ui'
import { alpha, lighten } from '@theme-ui/color'
import axios from 'axios'
import { Heart, ExternalLink } from 'react-feather'

import Tag from './tag'

const ArticleCard = ({ article }) => {
  const { colorMode } = useThemeUI()
  const [heartFilled, setHeartFilled] = useState(false)

  const occurrences = {
    m: 'Month',
    y: 'Year',
  }
  const shadowColor =
    colorMode === 'light'
      ? alpha(article.brand_color, 0.7).call()
      : lighten(article.brand_color, 0.1).call()

  const onHeartClick = () => {
    axios.get(`/api/articles/${article.id}/incrementHearts`).then(data => {
      setHeartFilled(heartFilled => !heartFilled)
    })
  }

  return (
    <Card
      variant="article"
      sx={{
        '&:hover': {
          boxShadow: `6px 6px 0 0 ${shadowColor}`,
        },
      }}
    >
      <Grid gap={3}>
        <Box>
          <Flex sx={{ alignItems: 'flex-start' }}>
            <Box>
              <Image
                sx={{
                  width: 50,
                  height: 50,
                }}
                src={article.logo.src}
                alt={`${article.name} logo`}
              />
            </Box>
            <div sx={{ mx: 'auto' }} />
            <Box>
              <Flex
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {article.available_free && (
                  <Box>
                    <Text variant="freeTag">🎉 &nbsp; Available Free</Text>
                  </Box>
                )}
                <Flex
                  sx={{
                    ml: 3,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IconButton
                    variant="heart"
                    aria-label={`Like ${article.name}`}
                    onClick={onHeartClick}
                  >
                    <Heart color={null} fill={heartFilled ? 'red' : 'gray'} />
                  </IconButton>
                  <Text variant="tag">{article.hearts}</Text>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        </Box>
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        >
          <Text variant="heading">{article.name}</Text>
          <a
            name={`Go to ${article.name}`}
            href={article.link}
            target="_blank"
            sx={{ variant: 'links.nav' }}
            aria-label={`Go to ${article.name}`}
          >
            <ExternalLink size={16} />
          </a>
        </Box>
        <Box
          sx={{
            minHeight: 64,
          }}
        >
          <Text variant="body">{article.description}</Text>
        </Box>
        <Box>
          <Text variant="body2">
            starts from ${article.price.value}/
            {occurrences[article.price.occurrence]}
          </Text>
        </Box>
        <Box>
          <Flex
            sx={{
              flexWrap: 'wrap',
              justifyContent: 'flex-start',
            }}
          >
            {article.categories.map(tag => (
              <Tag key={tag.name} tag={tag} />
            ))}
          </Flex>
        </Box>
      </Grid>
    </Card>
  )
}

ArticleCard.propTypes = {
  article: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    brand_color: PropTypes.string,
    price: PropTypes.shape({
      value: PropTypes.string,
      occurrence: PropTypes.string,
    }),
    logo: PropTypes.shape({
      src: PropTypes.string,
    }),
    categories: PropTypes.arrayOf(PropTypes.object),
    hearts: PropTypes.string,
    available_free: PropTypes.bool,
  }),
}

export default ArticleCard
